//import io from 'socket.io-client' ; 
import { Axios } from "axios"
import { io } from "socket.io-client";

export interface ServerProps {
    server : Axios
}

// * SOCKET CONFIG ///////////``
//export const socket_server = io('http://localhost:4000', { transports : ['polling']});
export const socket_server = io('https://mynyomi.fr', { transports : ['polling']});

// * NODEJS SERVER ///////////
export const server_host = (route:string="") => { return `https://mynyomi.fr/auth/${route}`}
//export const server_host = (route:string="") => { return `http://localhost:4000/auth/${route}`}


// * REDIRECT URL ///////////
export const redirect_url_admin = `https://admin.mynyomi.fr/`
export const redirect_url_app = `https://app.mynyomi.fr/`